@import '@/assets/scss/mixin.scss';

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 52px 10px 156px 10px;

  @include respond-to(512px) {
    padding: 0 10px 100px 10px;
  }
}

.menu_title {
  @include mediumText(60px, 85px);
  margin-bottom: 24px;

  @include respond-to(768px) {
    @include mediumText(45px, 65px);
    width: 100%;
    text-align: start;
  }

  @include respond-to(512px) {
    @include mediumText(24px, 29px);
  }
}

.menu_text {
  @include regularText(18px);
  max-width: 680px;
  text-align: center;
  margin: 0;

  @include respond-to(768px) {
    @include regularText(14px);
    max-width: 100%;
    text-align: start;
  }

  @include respond-to(512px) {
    @include regularText(14px, 20px);
    text-align: start;
    padding-right: 20px;
  }
}

.menu_image {
  margin-top: 64px;
  max-width: 95%;
  height: 100%;

  @include respond-to(980px) {
    max-width: 100%;
  }

  @include respond-to(512px) {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
}
